import {
	Divider,
	Stack,
	Text,
	Box,
	SimpleGrid,
	useColorMode
} from "@chakra-ui/react";
import { Fade } from "react-reveal";
import ProjectsArray from "./ProjectsArray";
import ProjectCard from "./ProjectCard";
import { COLORS } from "../style/colors";

export default function Projects() {
	const projects = ProjectsArray();
	// const others = OtherProjectsArray();
	// const options = TagsArray("ProjectsTags");
	const colorMode = useColorMode();

	// const [selected, setSelected] = useState("All");

	// const handleSelected = (value) => {
	//   setSelected(value);
	// };

	return (
		<div id="projects">
			<Stack
				as={Box}
				textAlign={"center"}
				spacing={{ base: 8, md: 14 }}
				pb={{ base: 20, md: 36 }}
			>
				<Stack align="center" direction="row" p={4}>
					<Divider orientation="horizontal" />
					<Text
						color={COLORS.websiteDefiningColor(colorMode)}
						fontWeight={800}
						fontSize={"x-large"}
						mx={4}
					>
						Projects
					</Text>
					<Divider orientation="horizontal" />
				</Stack>

				<SimpleGrid minChildWidth="500px" px={4} spacing={4}>
					{projects.map((project) => (
						<Fade bottom>
							<ProjectCard project={project} />
						</Fade>
					))}
				</SimpleGrid>
				{/* <Text color={"gray.600"} fontSize={"xl"} px={4}>
					Other Projects
				</Text>
				<Center px={4}>
					<ButtonGroup variant="outline">
						<Button
							colorScheme={
								selected === "All" ? `${color}` : "gray"
							}
							onClick={() => handleSelected("All")}
						>
							All
						</Button>
						{options.map((option) => (
							<Button
								colorScheme={
									selected === option.value
										? `${color}`
										: "gray"
								}
								onClick={() => handleSelected(option.value)}
							>
								{option.value}
							</Button>
						))}
					</ButtonGroup>
				</Center>
				<SimpleGrid columns={[1, 2, 3]} px={4} spacing={4}>
					{others
						.filter((other) => {
							if (selected === "All") {
								return true;
							} else {
								return other.tags.includes(selected);
							}
						})
						.map((other) => (
							<Fade bottom>
								<Card key={other.name}>
									<Stack>
										<CardBody
											align="left"
											h={[null, "40vh"]}
										>
											<Heading size="sm">
												{other.name}
											</Heading>

											<Text fontSize="sm" py={2}>
												{other.description}
											</Text>

											<HStack spacing={2}>
												{other.buttons.map((button) => (
													<Link
														key={button.text}
														href={button.href}
														color={`${color}.400`}
													>
														{button.text}
													</Link>
												))}
											</HStack>
											<HStack
												flexWrap="wrap"
												pt={4}
												spacing={2}
											>
												{other.badges.map((badge) => (
													<Badge
														my={2}
														key={badge.text}
														colorScheme={
															badge.colorScheme
														}
													>
														{badge.text}
													</Badge>
												))}
											</HStack>
										</CardBody>
									</Stack>
								</Card>
							</Fade>
						))}
				</SimpleGrid> */}
			</Stack>
		</div>
	);
}
