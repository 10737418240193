import React from 'react';
import { Container } from '@chakra-ui/react';
import './style/App.css';
import Nav from './components/NavBar';
import Header from './components/Header';
import Projects from './components/Projects';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Summary from './components/Summary';

function App() {

  return (
		<>
			<Nav />
			<Container maxW={"1800px"}>
				<Header />
				<Summary />
				<Projects />
				<Contact />
			</Container>
			<Footer />
		</>
  );
}

export default App;
