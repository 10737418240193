import {
	Divider,
	Stack,
	Text,
	Box,
	useColorMode,
	Center
} from "@chakra-ui/react";
// import { FaPython } from "react-icons/fa";
import { SiCplusplus } from "react-icons/si";
import { DiPython, DiJavascript1, DiDjango, DiReact, DiPostgresql, DiRedis, DiMysql, DiDocker, DiHtml5 } from "react-icons/di";
// import FlaskIcon from "../images/flask.svg";
import {ReactComponent as FlaskIcon} from "../images/flask.svg";
import { ReactComponent as FastAPIIcon } from "../images/fastapi.svg";
import { ReactComponent as TensorflowIcon } from "../images/tensorflow.svg";
import { ReactComponent as KerasIcon } from "../images/keras.svg";
import { ReactComponent as ScikitlearnIcon } from "../images/scikitlearn.svg";
import IconTooltip from "./IconTooltip";
import ProfileArray from "./ProfileArray";
import { COLORS } from "../style/colors";

export default function Summary() {
	const profile = ProfileArray();
	var colorMode = useColorMode();
	const languageIconSize = "100px";
	const iconSize = "70px";
	return (
		<div id="summary">
			<Stack
				as={Box}
				textAlign={"center"}
				spacing={{ base: 8, md: 14 }}
				pb={{ base: 20, md: 36 }}
			>
				<Stack align="center" direction="row" px={4}>
					<Divider orientation="horizontal" />
					<Text color={COLORS.websiteDefiningColor(colorMode)} fontWeight={800} fontSize={"x-large"} mx={4}>
						Summary
					</Text>
					<Divider orientation="horizontal" />
				</Stack>
				<Text
					color={COLORS.textColor(colorMode)}
					fontSize={"xl"}
					px={4}
				>
					{profile.about}
				</Text>
				<Text fontSize="x-large">Skills:</Text>
				<Center>
					<Stack align="center" direction="row">
						<IconTooltip label="Python">
							<DiPython size={languageIconSize} />
						</IconTooltip>
						<IconTooltip label="JavaScript">
							<DiJavascript1 size={languageIconSize} />
						</IconTooltip>
						<IconTooltip label="C++">
							<SiCplusplus size={languageIconSize} />
						</IconTooltip>
					</Stack>
				</Center>
				<Center>
					<Stack align="center" direction="row">
						<IconTooltip label="Django">
							<DiDjango size={iconSize} />
						</IconTooltip>
						<IconTooltip label="Flask">
							<FlaskIcon
								fill={COLORS.iconColor(colorMode)}
								width={iconSize}
								height={iconSize}
							/>
						</IconTooltip>
						<IconTooltip label="FastAPI">
							<FastAPIIcon
								fill={COLORS.iconColor(colorMode)}
								width={iconSize}
								height={iconSize}
							/>
						</IconTooltip>
						<IconTooltip label="React">
							<DiReact size={iconSize} />
						</IconTooltip>
						<IconTooltip label="Tensorflow">
							<TensorflowIcon
								fill={COLORS.iconColor(colorMode)}
								width={iconSize}
								height={iconSize}
							/>
						</IconTooltip>
						<IconTooltip label="Keras">
							<KerasIcon
								fill={COLORS.iconColor(colorMode)}
								width={iconSize}
								height={iconSize}
							/>
						</IconTooltip>
						<IconTooltip label="Scikit-learn">
							<ScikitlearnIcon
								fill={COLORS.iconColor(colorMode)}
								width={iconSize}
								height={iconSize}
							/>
						</IconTooltip>
						<IconTooltip label="PostgreSQL">
							<DiPostgresql size={iconSize} />
						</IconTooltip>
						<IconTooltip label="MySQL">
							<DiMysql size={iconSize} />
						</IconTooltip>
						<IconTooltip label="Redis">
							<DiRedis size={iconSize} />
						</IconTooltip>
						<IconTooltip label="Docker">
							<DiDocker size={iconSize} />
						</IconTooltip>
						<IconTooltip label="HTML5">
							<DiHtml5 size={iconSize} />
						</IconTooltip>
					</Stack>
				</Center>
			</Stack>
		</div>
	);
}

