import {
	Stack,
	Text,
	HStack,
	Card,
	CardBody,
	Image,
	Heading,
	Badge,
} from "@chakra-ui/react";
// import { COLORS } from "../style/colors";

export default function ProjectCard({ project, props }) {
	// const colorMode = useColorMode();

	return (
		<Card
			minH={"100px"}
			minw={"100px"}
			maxH={"600px"}
			key={project.name}
			direction={{
				base: "column-reverse",
				
			}}
			overflow="hidden"
		>
			<Stack>
				<CardBody align="left">
					<Heading size="md">{project.name}</Heading>

					<Text py={2}>{project.description}</Text>

					{/* <HStack py={2}>
						{project.buttons.map((button) => (
							<a key={button.text} href={button.href}>
								<Button color={COLORS.websiteDefiningColor(colorMode)}>
									{button.text}
								</Button>
							</a>
						))}
					</HStack> */}
					<HStack pt={4} spacing={2}>
						{project.badges.map((badge) => (
							<Badge
								key={badge.text}
								colorScheme={badge.colorScheme}
							>
								{badge.text}
							</Badge>
						))}
					</HStack>
				</CardBody>
			</Stack>

			<Image
				objectFit="contain"
				src={project.image}
			/>
		</Card>
	);
}
