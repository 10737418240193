import {
  Box,
  Heading,
  Text,
  Button,
  Stack,
  useColorMode
} from "@chakra-ui/react";
import ProfileArray from "./ProfileArray";
import { COLORS } from "../style/colors";

export default function Header() {
  const colorMode = useColorMode();
  const profile = ProfileArray();
  const scrollToContact = () => {
    const contactSection = document.querySelector("#contact");
    contactSection.scrollIntoView({ behavior: "smooth" });
  };
  const openLinkedin = () => {
    window.open(
                `${profile.linkedin}`,
                "_blank",
                "noreferrer,noopener"
              );
  };
  return (
		<div id="header">
				<Stack
					as={Box}
					textAlign={"center"}
					spacing={{ base: 8, md: 14 }}
					pb={{ base: 20, md: 36 }}
					pt={{ base: 36, md: 52 }}
				>
					<Heading
						fontWeight={600}
						fontSize={{ base: "2xl", sm: "4xl", md: "6xl" }}
						lineHeight={"110%"}
					>
						{profile.headerName} <br />
						<Text
							as={"span"}
							color={COLORS.websiteDefiningColor(colorMode)}
						>
							{profile.headerRole}
						</Text>
					</Heading>
					<Text
						color={COLORS.textColor(colorMode)}
						fontSize={{ base: "lg", sm: "xl", md: "2xl" }}
					>
						{profile.headerDesc}
					</Text>
					<Stack
						direction={"column"}
						spacing={3}
						align={"center"}
						alignSelf={"center"}
						position={"relative"}
					>
						<Button
							colorScheme={COLORS.websiteDefiningColor(colorMode)}
							bg={COLORS.websiteDefiningColor(colorMode)}
							rounded={"full"}
							px={6}
							_hover={{
								bg: COLORS.buttonHoverColor,
							}}
							onClick={openLinkedin}
						>
							Let's connect!
						</Button>
						<Button
							variant={"link"}
							colorScheme={"blue"}
							size={"sm"}
							onClick={scrollToContact}
						>
							Contact Me
						</Button>
					</Stack>
				</Stack>
		</div>
  );
}
