import {
  Divider,
  Stack,
  Text,
  Container,
  Box,
  HStack,
  Heading,
  Center,
  Button,
  useColorMode
} from "@chakra-ui/react";
import { FaLinkedin, FaGithub, FaEnvelope } from "react-icons/fa";
import ProfileArray from "./ProfileArray";
import { COLORS } from "../style/colors";

export default function Contact() {
  const profile = ProfileArray();
  const colorMode = useColorMode();
  const linkedin = () => {
    window.open(`${profile.linkedin}`, "_blank", "noreferrer,noopener");
  };
  const github = () => {
    window.open(`${profile.github}`, "_blank", "noreferrer,noopener");
  };
  const email = () => {
    window.open(`mailto:${profile.email}`, "_blank", "noreferrer,noopener");
  };
  return (
		<>
			<Container maxW={"3xl"} id="contact">
				<Stack
					as={Box}
					textAlign={"center"}
					spacing={{ base: 8, md: 14 }}
					pb={{ base: 20, md: 36 }}
				>
					<Stack align="center" direction="row" p={4}>
						<Divider orientation="horizontal" />
						<Text
							color={COLORS.websiteDefiningColor(colorMode)}
							fontWeight={800}
							fontSize={"x-large"}
							mx={4}
						>
							Contact
						</Text>
						<Divider orientation="horizontal" />
					</Stack>
					<Stack
						spacing={4}
						as={Container}
						maxW={"3xl"}
						textAlign={"center"}
					>
						<Heading fontSize={"3xl"}>Let's stay in touch!</Heading>
						{/* <Text
							color={COLORS.textColor(useColorMode)}
							fontSize={"xl"}
							px={4}
						>
							{profile.contact}
						</Text> */}
						{/* <Text
							color={COLORS.websiteDefiningColor(useColorMode)}
							fontWeight={600}
							fontSize={"lg"}
							px={4}
						>
							{profile.email}
						</Text> */}
						<Center>
							<HStack pt={4} spacing={4}>
								<Button variant="ghost" onClick={linkedin}>
									<FaLinkedin size={28} />
								</Button>
								<Button variant="ghost" onClick={github}>
									<FaGithub size={28} />
								</Button>
								<Button variant="ghost" onClick={email}>
									<FaEnvelope size={28} />
								</Button>
							</HStack>
						</Center>
					</Stack>
				</Stack>
			</Container>
		</>
  );
}

