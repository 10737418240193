import React from "react";
import { Box, Tooltip } from "@chakra-ui/react";

export default function IconTooltip(props) {
	const { children, ...rest } = props;

	return (
		<Tooltip hasArrow {...rest}>
			<Box>{children}</Box>
		</Tooltip>
	);
}
