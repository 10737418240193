import { mode } from '@chakra-ui/theme-tools';

const COLOR_LIGHT = '#326698'; 
const COLOR_DARK = '#326698';
// colors which define the website
const WEBSITE_DEFINING_COLOR_LIGHT = "teal.400";
const WEBSITE_DEFINING_COLOR_DARK = "teal.400";
// color of lengthy text
const TEXT_COLOR_LIGHT = "gray.700";
const TEXT_COLOR_DARK = "gray.300";
// hover over collor of buttons
const BUTTON_HOVER_COLOR = "teal.200";
// color of icons
const ICON_COLOR_LIGHT = "black";
const ICON_COLOR_DARK = "white";

export const COLORS = {
	colorLight: COLOR_LIGHT,
	colorDark: COLOR_DARK,
	buttonMode: mode(COLOR_LIGHT, COLOR_DARK),
	websiteDefiningColor: mode(
		WEBSITE_DEFINING_COLOR_LIGHT,
		WEBSITE_DEFINING_COLOR_DARK
	),
	textColor: mode(TEXT_COLOR_LIGHT, TEXT_COLOR_DARK),
	iconColor: mode(ICON_COLOR_LIGHT, ICON_COLOR_DARK),
	buttonHoverColor: BUTTON_HOVER_COLOR,
};
